var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "iconSlider" }, [
    _c("div", { staticClass: "iconSlider__container" }, [
      _c(
        "div",
        { staticClass: "iconSlider__col" },
        [
          _c("span", { staticClass: "iconSlider__subTitle" }, [
            _vm._v(_vm._s(_vm.iconSlider.subTitle)),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "iconSlider__title" }, [
            _vm._v(_vm._s(_vm.iconSlider.title)),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "iconSlider__teaser" }, [
            _vm._v(_vm._s(_vm.iconSlider.teaser)),
          ]),
          _vm._v(" "),
          _vm.iconSlider.buttonText !== ""
            ? _c("Btn", { attrs: { href: _vm.iconSlider.buttonLink } }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.iconSlider.buttonText) +
                    "\n            "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "iconSlider__col" }, [
        _c(
          "div",
          { staticClass: "iconSlider__colInner" },
          [
            _vm.sliderItems
              ? _c(
                  "hooper",
                  {
                    key: "slider-" + _vm.hooperKey,
                    ref: "hooper",
                    attrs: {
                      itemsToShow: _vm.itemsToShow + 1.25,
                      trimWhiteSpace: "",
                    },
                    on: { slide: _vm.updateCarousel },
                  },
                  _vm._l(_vm.sliderItems, function (item, index) {
                    return _c(
                      "slide",
                      {
                        key: "sliderItem-" + index,
                        staticClass: "iconSlider__item",
                      },
                      [
                        _c("icon", {
                          class:
                            "iconSlider__itemIcon iconSlider__itemIcon--" +
                            item.color,
                          attrs: { icon: item.icon },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "iconSlider__itemTitle" }, [
                          _vm._v(_vm._s(item.label)),
                        ]),
                      ],
                      1
                    )
                  }),
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.sliderItems.length > _vm.itemsToShow + 1.25
              ? _c("RangeSlider", {
                  attrs: { max: _vm.maxSliderBar, "for-teaser": "" },
                  model: {
                    value: _vm.carouselData,
                    callback: function ($$v) {
                      _vm.carouselData = $$v
                    },
                    expression: "carouselData",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }