/*
 * Copyright 2020 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2020.06.26 at 18:48
 */

import {HelferleinEventList} from '@labor-digital/helferlein/lib/Events/HelferleinEventList';
import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import {AppStoreKeys} from '../AppStoreKeys';

export default {
    props: {
        context: null as ContentElementContext
    },
    data()
    {
        return {
            hooperKey: 1,
            itemsToShow: 3.25,
            itemsToShowDefault: 3.25,
            carouselData: 0
        };
    },
    methods: {
        onResizeRerenderHooper()
        {
            this.hooperKey++;
            const breakpoint = this.context.store.get(AppStoreKeys.BREAKPOINT);
            if (breakpoint.is('<=', 'lg') && breakpoint.is('>', 'xs')) {
                this.itemsToShow = 1.75;
                this.barWidth = '70%';
            } else if (breakpoint.is('<=', 'xs')) {
                this.itemsToShow = 1.1;
                this.barWidth = '70%';
            } else {
                this.itemsToShow = this.itemsToShowDefault;
                this.barWidth = '50%';
            }
        },
        updateCarousel(payload)
        {
            this.carouselData = parseFloat(payload.currentSlide.toFixed(1));
        }
    },
    watch: {
        carouselData(n)
        {
            this.$refs.hooper.slideTo(n);
        }
    },
    mounted()
    {
        this.onResizeRerenderHooper();
        this.proxy.bind(
            this.context.appContext.eventEmitter,
            HelferleinEventList.EVENT_RESIZE_THROTTLED,
            this.onResizeRerenderHooper
        );
    }
};
