
















































import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import {Hooper, Pagination as HooperPagination, Slide} from 'hooper';
import RangeSlider from '../../../Component/Form/RangeSlider/RangeSlider.vue';
// import VueSlider from 'vue-slider-component';
// import 'vue-slider-component/theme/antd.css';
import Btn from '../../../Component/Misc/Btn/Btn.vue';
import Icon from '../../../Component/Misc/Icon/Icon.vue';
import LinkWrap from '../../../Component/Misc/Link/LinkWrap/LinkWrap.vue';
import ComponentProxyAwareMixin from '../../../Mixin/ComponentProxyAwareMixin';
import SliderMixin from '../../../Mixin/SliderMixin';

export default {
    name: 'IconSlider',
    components: {RangeSlider, LinkWrap, Icon, Btn, Hooper, Slide, HooperPagination},
    mixins: [SliderMixin, ComponentProxyAwareMixin],
    props: {
        context: ContentElementContext
    },
    data()
    {
        return {
            itemsToShow: 5,
            itemsToShowDefault: 5
        };
    },
    computed: {
        maxSliderBar()
        {
            return parseFloat((this.sliderItems.length - this.itemsToShow - 1.25).toFixed(1));
        },
        sliderItems()
        {
            return this.context.data.get('items', []);
        },
        iconSlider()
        {
            return this.context.data.get('iconSlider', []);
        }
    }
};
