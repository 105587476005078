var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("VuTemplate", {
    staticClass: "rangeSlider",
    class: { "rangeSlider--teaser": _vm.forTeaser },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }