























import {VuFormRangeSliderAbstract} from '@labor-digital/vuetiful/src/index';

export default {
    name: 'RangeSlider',
    extends: VuFormRangeSliderAbstract,
    props: {
        forTeaser: {
            type: Boolean,
            default: false
        }
    }
};
